import { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { Drawer } from "antd";

import getMyDate from "../../../components/getMyDate";

import DevicePlaylists from "./DevicePlaylists";

/*import DevicePaymentHistory from "./DevicePaymentHistory";

import RestoreLogs from "./RestoreLogs";*/

import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import TEXT from "../../../config/text";

import classes from "../style/deviceManagerDraver.module.scss";

/*import ActivationsLogs from "./ActivationsLogs";*/

const DeviceManagerDrawer = ({ onClose, visible, userInfo }) => {
  const translation = useSelector(selectTranslation);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleChangeWindowInnerWidth = () =>
      setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleChangeWindowInnerWidth);
    return () =>
      window.removeEventListener("resize", handleChangeWindowInnerWidth);
  }, []);

  return (
    <Drawer
      title={`Device info`}
      placement="right"
      onClose={onClose}
      open={visible}
      width={windowWidth > 880 ? 880 : "83vw"}
    >
      <div className={classes["userInfo_container"]}>
        <div className={classes["userInfo_div"]}>
          <ul className={classes["userInfo_list"]}>
            <li className={classes["user-info_item"]}>
              {" "}
              {translation["Model"] || TEXT["Model"]}: {userInfo?.model} {userInfo?.os_version && `(${userInfo.os_version})`}
            </li>
            <li className={classes["user-info_item"]}>
              {" "}
              {translation["Online"] || TEXT["Online"]}:{" "}
              {userInfo?.online ? "YES" : "NO"}
            </li>
            <li className={classes["user-info_item"]}>
              {" "}
              {translation["Payed"] || TEXT["Payed"]}:{" "}
              {userInfo?.payed ? "YES" : "NO"}
            </li>
            <li className={classes["user-info_item"]}>
              {" "}
              {translation["Last online"] || TEXT["Last online"]}:{" "}
              {userInfo?.last_online ? getMyDate(userInfo?.last_online) : "N/A"}
            </li>
            <li className={classes["user-info_item"]}>
              {translation["Mac"] || TEXT["Mac"]}: {userInfo?.mac}
            </li>
            <li className={classes["user-info_item"]}>
              {translation["Restore Key"] || TEXT["Restore Key"]}:{" "}
              {userInfo?.restore_key ? userInfo.restore_key : "N/A"}
            </li>
            <li className={classes["user-info_item"]}>
              {userInfo?.payed ? translation["Expired date"] || TEXT["Expired date"] : translation["Free trial expired"] ||
                TEXT["Free trial expired"]}:{" "}
              {
                userInfo?.payed ?
                  getMyDate(userInfo?.activation_expired) : userInfo?.free_trial == '1' ?
                    getMyDate(userInfo?.free_trial_expired) :
                    "Expired"
              }
            </li>
            <li className={classes["user-info_item"]}>
              {translation["Ip"] || TEXT["Ip"]}:{" "}
              {userInfo?.ip ? userInfo.ip : "N/A"}
            </li>
            <li className={classes["user-info_item"]}>
              {translation["App version"] || TEXT["App version"]}:{" "}
              {userInfo?.app_version ? userInfo.app_version : "N/A"}
            </li>
            <li className={classes["user-info_item"]}>
              {translation["Authorization type"] || TEXT["Authorization type"]}:{" "}
              {userInfo?.auth_type == "otp" ? "Otp" : "Device key"}
            </li>
            <li className={classes["user-info_item"]}>
              {translation["Otp"] || TEXT["Otp"]}:{" "}
              {userInfo?.code ? userInfo?.code : "N/A"}
            </li>
            <li className={classes["user-info_item"]}>
              {translation["Device key"] || TEXT["Device key"]}:{" "}
              {userInfo?.key ? userInfo?.key : "N/A"}
            </li>
          </ul>
        </div>
      </div>
      {
        userInfo && <>
          <DevicePlaylists userInfo={userInfo} />
        </>
      }
    </Drawer>
  );
};

export default DeviceManagerDrawer;
