import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function TextEditor({ content,onTranslate,item }) {
  return content &&  (
    <div>
      <h1>{content[0]["label"]}</h1>
      <ReactQuill
        theme="snow"
        value={content[1]["value"]}
        onChange={(value)=>{
          onTranslate(value,content[1]["key"],item)}}
      />
    </div>
  );
}
