import request from "./request";
import URLS from "./url";
import requestQuery from "./requestQuery";

const REQUESTS = {
  DEVICE_LOGS: (query, callback) => {
    return request("get", `${URLS.DEVICE_LOGS}${requestQuery(query)}`, {}, callback);
  },
  SUBTITLE: {
    GET: (callback) => {
      request("get", URLS.SUBTITLE, {}, callback);
    },
    PUT: (body, callback, errorCallback) => {
      request("put", URLS.SUBTITLE, body, callback, errorCallback);
    },
  },
  LOGIN: (body, callback, errorCallback) => {
    request("post", URLS.login, body, callback, errorCallback);
  },

  FORGOT_PASSWORD: (body, callback, errorCallback) => {
    request("post", `${URLS.forgot_password}`, body, callback, errorCallback);
  },
  TRANSFER_RESSELLER: (body, callback,errorCallback) => {
    request("put", `${URLS.TRANSFER_RESSELLER}`, body, callback,errorCallback);
  },
  DEVICE_ACTIVATIONS: (query, callback, errorCallback) => { 
    request(
      "get",
      URLS.DEVICE_ACTIVATIONS + requestQuery(query),
      {},
      callback,
      errorCallback
    );
  },
  RESET_PASSWORD: (body, callback, errorCallback) => {
    request("post", `${URLS.reset_password}`, body, callback, errorCallback);
  },

  PROFILE: {
    GET: (callback, errorCallback) => {
      request("get", URLS.admin, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.admin, body, callback, errorCallback);
    },
  },

  LANGUAGES: (query, callback) => {
    request(
      "get",
      `${URLS.admin_languages}${requestQuery(query)}`,
      {},
      callback
    );
  },

  LIVES_CHART: (callback, query) => {
    return request(
      "get",
      `${URLS.LIVES_CHART}${requestQuery(query)}`,
      {},
      callback
    );
  },

  GET_LIVES: (callback, errorCallback, query) => {
    return request(
      "get",
      `${URLS.LIVES}${requestQuery(query)}`,
      {},
      callback,
      errorCallback
    );
  },

  MOVIES_CHART: (callback, query) => {
    return request(
      "get",
      `${URLS.MOVIES_CHART}${requestQuery(query)}`,
      {},
      callback
    );
  },

  GET_MOVIES: (callback, errorCallback, query) => {
    return request(
      "get",
      `${URLS.MOVIES}${requestQuery(query)}`,
      {},
      callback,
      errorCallback
    );
  },

  GET_SERVER: (callback) => {
    request("get", URLS.admin_server, {}, callback);
  },

  STATISTICS: {
    TOTALS: (callback) => {
      request("get", URLS.get_totals, {}, callback);
    },
    ONLINE_CLIENTS: (callback) => {
      request("get", URLS.online_clients, {}, callback);
    },
    SERVER_STATE: (callback) => {
      request("get", URLS.server_state, {}, callback);
    },

    SERVER: (callback) => {
      request("get", URLS.admin_server, {}, callback);
    },
    USED_DEVICES: (callback) => {
      request("get", URLS.admin_server, {}, callback);
    },

    COUNT_USED_DEVICES: (callback) => {
      request("get", URLS.used_devices, {}, callback);
    },
    CHART: (callback) => {
      request("get", URLS.chart, {}, callback);
    },


    DEVICE_CHART: (callback, errorCallback, query) => {
      return request(
        "get",
        `${URLS.device_chart}${requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },

    CART_PAYMENTS: (callback, errorCallback, query) => {
      return request(
        "get",
        `${URLS.chart_payments + requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },
  },

  TEXT_TRANSLATION: (callback, errorCallback, query) => {
    return request(
      "get",
      `${URLS.admin_translations + requestQuery(query)}`,
      {},
      callback,
      errorCallback
    );
  },

  REFERRAL_RESELLER: {
    GET: (query, callback) => {
      request("get", URLS.admin_reseller + requestQuery(query), {}, callback);
    },

    DISABLE: (id, callback, errorCallback) => {
      request("post", `${URLS.DISABLE}`, { id }, callback, errorCallback);
    },

    ADD: (body, callback, errorCallback) => {
      request("post", URLS.admin_reseller, body, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.admin_reseller, body, callback, errorCallback);
    },
  },

  NOTIFICATIONS: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.NOTIFICATION + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },

    ADD: (body, callback, errorCallback) => {
      request("post", URLS.NOTIFICATION, body, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.NOTIFICATION, body, callback, errorCallback);
    },

    DELETE: (body, callback, errorCallback) => {
      request("delete", URLS.NOTIFICATION, body, callback, errorCallback);
    },
  },

  NOTIFICATION_FREEZE: (body, callback, errorCallback) => {
    return request(
      "put",
      URLS.NOTIFICATION_FREEZE,
      body,
      callback,
      errorCallback
    );
  },

  GET_REFERRALS: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.REFERRAL + requestQuery(query),
      {},
      callback,
      errorCallback
    );
  },

  GET_RESELLERS: (query, callback) => {
    request("get", URLS.RESELLER + requestQuery(query), {}, callback);
  },

  PACKEGES: {
    GET: (query, callback) => {
      request(
        "get",
        URLS.activation_package + requestQuery(query),
        {},
        callback
      );
    },
    ADD: (body, callback, errorCallback) => {
      request("post", URLS.activation_package, body, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.activation_package, body, callback, errorCallback);
    },

    DELETE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.activation_package}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  STATUS: {
    GET: (query, callback) => {
      request("get", URLS.referral_status + requestQuery(query), {}, callback);
    },

    ADD: (body, callback, errorCallback) => {
      request("post", URLS.referral_status, body, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.referral_status, body, callback, errorCallback);
    },

    DELETE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.referral_status}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  BLACKLIST: {
    GET: (query, callback) => {
      request("get", URLS.black_lists + requestQuery(query), {}, callback);
    },

    ADD: (body, callback, errorCallback) => {
      request("post", URLS.black_lists, body, callback, errorCallback);
    },

    DELETE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.black_lists}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  DEVICE: {
    //sort:JSON.stringify(["createdAt","DESC"])
    GET: (query, callback) => {
      request("get", URLS.admin_devices + requestQuery(query), {}, callback);
    },

    ACTIVATE_DEVICE: (body, callback, errorCallback) => {
      request("post", URLS.activate_device, body, callback, errorCallback);
    },
    FREE_TRIAL: (body, callback, errorCallback) => {
      request("post", URLS.reset_free_trial, body, callback, errorCallback);
    },
    GET_PACKAGES: (callback) => {
      request("get", URLS.get_packajes, {}, callback);
    },

    GET_DEVICE: (id, query, callback) => {
      request(
        "get",
        `${URLS.admin_devices}/${id}${requestQuery(query)}`,
        {},
        callback
      );
    },
    GET_PLAYLIST_LOGS: (query, callback) => {
      request(
        "get",
        `${URLS.playlist_logs}${requestQuery(query)}`,
        {},
        callback
      );
    },

    DELETE_DEVICE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.admin_devices}`,
        { id },
        callback,
        errorCallback
      );
    },

    DELETE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.admin_playlist}`,
        { id },
        callback,
        errorCallback
      );
    },
    ADD: (body, callback, errorCallback) => {
      request("post", URLS.admin_playlist, body, callback, errorCallback);
    },

    EDIT_PLAYLIST: (body, callback, errorCallback) => {
      request("put", URLS.admin_playlist, body, callback, errorCallback);
    },
  },

  WITHDRAW: {
    GET: (query, callback) => {
      request("get", URLS.withdraw + requestQuery(query), {}, callback);
    },

    UPDATE: (id, callback, errorCallback) => {
      return request(
        "post",
        `${URLS.withdraw}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  APP_INFO: {
    GET: (callback) => {
      request("get", URLS.api_app_info, {}, callback);
    },

    CURRENCIES: (query, callback) => {
      request("get", URLS.currencies + requestQuery(query), {}, callback);
    },

    UPDATE: (formData, callback, errorCallback) => {
      request("put", URLS.app_info, formData, callback, errorCallback);
    },
  },

  SERVER: {
    GET: (query, callback) => {
      request("get", URLS.admin_server + requestQuery(query), {}, callback);
    },
    ADD: (body, callback, errorCallback) => {
      request("post", URLS.admin_server, body, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.admin_server, body, callback, errorCallback);
    },

    EDIT_DEFAULT: (body, callback, errorCallback) => {
      request("put", URLS.server_default, body, callback, errorCallback);
    },

    DELETE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.admin_server}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  EARNINGS: {
    GET: (query, callback) => {
      request("get", URLS.adminEarnings + requestQuery(query), {}, callback);
    },
    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.adminEarnings, body, callback, errorCallback);
    },
  },

  ACTIVATION: {
    GET: (query, callback) => {
      request(
        "get",
        URLS.resellerActivation + requestQuery(query),
        {},
        callback
      );
    },

    EDIT: (body, callback, errorCallback) => {
      request("post", URLS.resellerActivation, body, callback, errorCallback);
    },
  },

  PAYMENTS: {
    GET: (query, callback) => {
      request("get", URLS.payments + requestQuery(query), {}, callback);
    },

    DELETE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.payments}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  REFERRAL_LINKS: {
    GET: (query, callback) => {
      request("get", URLS.referral_links + requestQuery(query), {}, callback);
    },
  },

  LENG: {
    GET: (query, callback) => {
      request("get", URLS.admin_languages + requestQuery(query), {}, callback);
    },
    WORDS: (callback, errorCallback, query) => {
      return request(
        "get",
        `${URLS.words + requestQuery(query)}`,
        {},
        callback,
        errorCallback
      );
    },
    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.admin_languages, body, callback, errorCallback);
    },

    TRANSLATIONS_PUT: (body, callback, errorCallback) => {
      request("put", URLS.admin_translations, body, callback, errorCallback);
    },

    ADD: (body, callback, errorCallback) => {
      request("post", URLS.admin_translations, body, callback, errorCallback);
    },
  },

  USER_PACKEGES: {
    GET: (query = {}, callback) => {
      request("get", URLS.user_packages + requestQuery(query), {}, callback);
    },
    ADD: (body, callback, errorCallback) => {
      request("post", URLS.user_packages, body, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.user_packages, body, callback, errorCallback);
    },

    EDIT_RECOMMENDING: (body, callback, errorCallback) => {
      request("put", URLS.change_recommending, body, callback, errorCallback);
    },

    DELETE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.user_packages}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  SMPT: {
    GET: (callback) => {
      request("get", URLS.smptConfig, {}, callback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.smptConfig, body, callback, errorCallback);
    },
  },

  NEWS: {
    GET: (query, callback) => {
      request("get", URLS.news + requestQuery(query), {}, callback);
    },

    ADD: (body, callback, errorCallback) => {
      request("post", URLS.news, body, callback, errorCallback);
    },

    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.news, body, callback, errorCallback);
    },

    ADD_GALERIES: (body, callback, errorCallback) => {
      request("post", URLS.galeries, body, callback, errorCallback);
    },

    DELETE: (id, callback, errorCallback) => {
      return request("delete", `${URLS.news}`, { id }, callback, errorCallback);
    },
  },

  TICKETS: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.tickets + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },

    EDIT_WIEWED: (body, callback, errorCallback) => {
      request("put", URLS.tickets_viewed, body, callback, errorCallback);
    },

    ANSWER: (body, callback, errorCallback) => {
      request("put", URLS.tickets_answer, body, callback, errorCallback);
    },

    WIEWED: (id, callback, errorCallback) => {
      return request(
        "put",
        `${URLS.tickets_viewed}`,
        { id },
        callback,
        errorCallback
      );
    },

    DELETE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.tickets}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  PAYMENT_SETTINGS: (callback) => {
    request("get", URLS.payment_settings, {}, callback);
  },

  EDIT_PAYMENT_SETTINGS: (body, callback, errorCallback) => {
    request("put", URLS.edit_payment_settings, body, callback, errorCallback);
  },

  DELETE: (id, callback, errorCallback) => {
    return request(
      "delete",
      `${URLS.tickets}`,
      { id },
      callback,
      errorCallback
    );
  },

  CHAT: {
    GET: (query, callback) => {
      request("get", URLS.chat + requestQuery(query), {}, callback);
    },

    GET_MESSAGES: (query, callback) => {
      request("get", URLS.chat_messages + requestQuery(query), {}, callback);
    },

    SEND: (body, callback, errorCallback) => {
      request("post", URLS.chat_messages, body, callback, errorCallback);
    },

    WIEWED: (body, callback) => {
      request("put", URLS.view_message, body, callback, {});
    },
  },

  COUPONS: {
    GET: (query, callback) => {
      request("get", URLS.coupons + requestQuery(query), {}, callback);
    },

    POST: (body, callback, errorCallback) => {
      request("post", URLS.coupons, body, callback, errorCallback);
    },

    DELETE: (id, callback, errorCallback) => {
      return request(
        "delete",
        `${URLS.coupons}`,
        { id },
        callback,
        errorCallback
      );
    },
  },

  WIDGETS: {
    GET: (callback) => {
      request("get", URLS.widgets, {}, callback);
    },
  },

  RESELLER_ACTIVATIONS: {
    POST: (body, callback, errorCallback) => {
      return request(
        "post",
        `${URLS.activations}`,
        body,
        callback,
        errorCallback
      );
    },
    PUT: (body, callback, errorCallback) => {
      return request(
        "put",
        `${URLS.activations}`,
        body,
        callback,
        errorCallback
      );
    },
  },

  TRANSLATIONS: {
    GET: (query, callback, errorCallback) => {
      return request(
        "get",
        `${URLS.TRANSLATIONS.GET}`,
        query,
        callback,
        errorCallback
      );
    },

    EDIT: (body, callback, errorCallback) => {
      request(
        "put",
        URLS.TRANSLATIONS.EDIT,
        { json_configs: JSON.stringify(body) },
        callback,
        errorCallback
      );
    },
  },
  RESELLER_TRANSLATION_UPDATE: (body, callback, errorCallback) => {
    request(
      "put",
      URLS.RESELLER_TRANSLATION_UPDATE,
      body,
      callback,
      errorCallback
    );
  },
  IMA_CONFIG: {
    GET: (callback) => {
      request("get", `${URLS.IMA_CONFIG}`, {}, callback);
    },
    PUT: (body, callback, errorCallback) => {
      request("put", `${URLS.IMA_CONFIG}`, body, callback, errorCallback);
    },
  },
  CAPTCHA_FOR_LOGIN: (callback) => {
    return request("get", `${URLS.CAPTCHA_FOR_LOGIN}`, {}, callback);
  },
  SENT_CAPTCHA_TOKEN: (body, callback, errorCallback) => {
    request("post", URLS.CAPTCHA_FOR_LOGIN, body, callback, errorCallback);
  },
  CAPTCHA: {
    GET: (callback) => {
      return request("get", `${URLS.CAPTCHA}`, {}, callback);
    },
    PUT: (body, callback, errorCallback) => {
      request("put", URLS.CAPTCHA, body, callback, errorCallback);
    },
  },

  COUNTRIES: {
    GET: (query, callback, errorCallback) => {
      request(
        "get",
        URLS.COUNTRIES + requestQuery(query),
        {},
        callback,
        errorCallback
      );
    },
    PUT: (body, callback, errorCallback) => {
      request("put", URLS.COUNTRIES, body, callback, errorCallback);
    },
  },

  XTREAM_UI: {
    GET: (callback, errorCallback) => {
      request("get", URLS.XTREAM_UI, {}, callback, errorCallback);
    },
    EDIT: (body, callback, errorCallback) => {
      request("put", URLS.XTREAM_UI, body, callback, errorCallback);
    },
  },
  RESELLER_ACTIVATIONS_HISTORY: (query, callback, errorCallback) => {
    request(
      "get",
      URLS.RESELLER_ACTIVATIONS_HISTORY + requestQuery(query),
      {},
      callback,
      errorCallback
    );
  },
  RESELLER_SUBRESELLERS: (query, callback) => {
    request(
      "get",
      URLS.RESELLER_SUBRESELLERS + requestQuery(query),
      {},
      callback
    );
  },

  TOTAL_DEVICES: (callback) => {
    request("get", URLS.TOTAL_DEVICES, {}, callback);
  },
  RESELLERS_REGISTERED: (callback) => {
    request("get", URLS.RESELLERS_REGISTERED, {}, callback);
  },
  RESELLERS_ACTIVATION: (callback) => {
    request("get", URLS.RESELLERS_ACTIVATION, {}, callback);
  },

  RESELLERS_DELETE: (callback, body) => {
    request("delete", URLS.RESELLER, body, callback);
  },

  DEVICE_PAYMENT: (callback) => {
    request("get", URLS.DEVICE_PAYMENT, {}, callback);
  },

  ONLINE_UESERS: (callback, query) => {
    request("get", URLS.ONLINE_USERS + requestQuery(query), {}, callback);
  },
  CATEGORIES: {
    GET: (callback, errorCallback) => {
      request("get", URLS.CATEGORIES, {}, callback, errorCallback);
    },
    PUT: (callback, errorCallback, body) => {
      request("put", URLS.CATEGORIES, body, callback, errorCallback);
    },
  },
  CONTENT_CATEGORIES: (callback, errorCallback) => {
    request("get", URLS.CONTENT_CATEGORIES, {}, callback, errorCallback);
  },
  GET_API_VERSION: (callback) => {
    request("get", URLS.GET_API_VERSION, {}, callback);
  },
  DEVICE_DEACTIVEATE: (body, callback, errorCallback) => {
    request("put", URLS.DEVICE_DEACTIVEATE, body, callback, errorCallback)
  },
  APPLICATIONS: {
    GET: (query, callback, errorCallback) => {
      request("get", URLS.APPLICATIONS + requestQuery(query), {}, callback, errorCallback)
    },
    POST: (body, callback, errorCallback, onUploadProgress) => {
      request("post", URLS.APPLICATIONS, body, callback, errorCallback, onUploadProgress)
    },
    PUT: (body, callback, errorCallback, onUploadProgress) => {
      request("put", URLS.APPLICATIONS, body, callback, errorCallback, onUploadProgress)
    },
    DELETE: (body, callback, errorCallback) => {
      request("delete", URLS.APPLICATIONS, body, callback, errorCallback)
    },
  },
  BACKUP_PLAYLIST: {
    GET: (callback, errorCallback) => {
      request("get", URLS.BACKUP_PLAYLIST, {}, callback, errorCallback)
    },
    PUT: (body, callback, errorCallback) => {
      request("put", URLS.BACKUP_PLAYLIST, body, callback, errorCallback)
    },
  },
  FREE_DNS: {
    GET: (query, callback, errorCallback) => {
      request("get", URLS.FREE_DNS + requestQuery(query), {}, callback, errorCallback)
    },
    PUT: (body, callback, errorCallback) => {
      request("put", URLS.FREE_DNS, body, callback, errorCallback)
    },
    POST: (body, callback, errorCallback) => {
      request("post", URLS.FREE_DNS, body, callback, errorCallback)
    },
    DELETE: (body, callback, errorCallback) => {
      request("delete", URLS.FREE_DNS, body, callback, errorCallback)
    },
  },
};

export default REQUESTS;
