import React, { useEffect, useState } from 'react';

import { Table, Button } from 'antd';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import TableButtons from '../../components/TableButtons';

import showPropsConfirm from '../../components/showPropsConfirm';

import REQUESTS from '../../api/requests';

import ApplicationDrawer from './components/ApplicationDrawer';

import styles from "./index.module.scss"


export default function Applications() {
    const [data, setData] = useState();

    const [total, setTotal] = useState();

    const [limit, setLimit] = useState(10);
    
    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState(false);

    const [visible, setVisible] = useState(false);

    const [current, setCurrent] = useState();

    const columns = [
        {
            title: "#",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            align: "center",
            width: "200px",
            render: (text, record, index) => {
                return <div dangerouslySetInnerHTML={{ __html: record?.title }} />
            }
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (text, record, index) => {
                return <div dangerouslySetInnerHTML={{ __html: record?.description }} />
            }
        },
        {
            title: "",
            dataIndex: "action",
            width: "60px",
            align: "center",
            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    buttons={[
                        { key: "edit", text: "edit", icon: <EditOutlined /> },
                        {
                            key: "delete",
                            text: "delete",
                            icon: <DeleteOutlined />,
                        },
                    ]}
                />
            ),
        },
    ];

    const deleteItem = (id) => {
        REQUESTS.APPLICATIONS.DELETE({ id }, (data) => {
            getData();
        })
    }

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "delete":
                showPropsConfirm(
                    item.id,
                    deleteItem,
                    loading,
                    "DELETE",
                    "#bf4342",
                );
                break;

            case "edit":
                setCurrent(item)
                setVisible(true);
                break;

            default:
                break;
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    const getData = () => {
        setLoading(true);

        const query = {
            page: currentPage,
            limit,
        }

        function callback(data) {
            setLoading(false);
            setData(data.rows)
            setTotal(data.count);
            setCurrentPage(data.currentPage);
        }

        function errorCallback(err) {
            setLoading(false);
        }

        REQUESTS.APPLICATIONS.GET(query, callback, errorCallback)
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            getData()
        }, 500);

        return () => clearTimeout(timer);
    }, [currentPage, limit,])

    return (
        <div>
            <h1 className={styles['title']}>Applications</h1>
            <div className={styles['button-container']}>
                <Button type="primary" icon={<PlusOutlined />} onClick={() => setVisible(true)} />
            </div>
            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={data}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
                size="small"
            />
            <ApplicationDrawer
                visible={visible}
                onClose={() => {
                    setVisible(false);
                    setCurrent(null);
                }}
                current={current}
                getData={getData}
            />
        </div>
    )
}
