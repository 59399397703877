import React, { useEffect, useState } from "react";

import { DatePicker, Select, Space } from "antd";

import { Line } from "@ant-design/plots";

import moment from 'moment';
import REQUESTS from "../../../api/requests";

import classes from "../styles/dashboard.module.scss";

const { Option } = Select;

const PickerWithType = ({ type,value, onChange ,defaultValue}) => {
  if (type === "week") return <DatePicker  picker={type} onChange={onChange} defaultValue={moment(new Date(),"MMM Do YY")} format={"YYYY-MM-DD"}/>;
  return <DatePicker picker={type} onChange={onChange} defaultValue={moment(defaultValue,"YYYY-MM-DD")} format={"YYYY-MM-DD"}/>;
};

export default function OnlineUsersStatistics({ getAgain }) {
  const [data, setData] = useState([]);

  const [selectedDate, setSelectedDate] = useState(`${(new Date()).getFullYear()}-01-01`);
  const [dateType, setDateType] = useState("year");

  const [platformType, setPlatformType] = useState("all");

  const config = {
    data,
    xField: dateType === "year" ? 'month' : "day",
    yField: 'count',
    seriesField: 'name',
    legend: {
      position: 'top',
    },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000,
      },
    },
  };

  const getOnlineUsers = () => {
    const query = {
      data_type: dateType,
      date: selectedDate,
      type: platformType,
    };

    REQUESTS.ONLINE_UESERS((data) => {
      
      if (Array.isArray(data)) {
        if (dateType === 'month' || dateType === 'week' ) {
          data = data.map((obj) => { return { ...obj, count: parseInt(obj.count) ,day:obj.day.toString()} })
        } else {
          data = data.map((obj) => { return { ...obj, count: parseInt(obj.count) } })
        }

        setData(data);

      } else {
        const clone = { ...data };

        const newData = [];

        for (let platform in clone) {
          for (let value of clone[platform]) {
            if (dateType === 'month' || dateType === 'week') {
              newData.push({ name: platform, count: parseInt(value.count),day:value.day.toString()});
            } else {
              newData.push({ name: platform, count: parseInt(value.count),month:value.month});
            }
          }
        }
        
        let _newData = newData.sort((a, b) => a.day - b.day)

        setData(_newData);
      }

    }, query);
  };

  useEffect(() => {
    getOnlineUsers();
  }, [selectedDate, dateType, platformType]);

  useEffect(() => {
    getOnlineUsers();
  }, [getAgain]);

  return (
    <div className={classes["online-statistics"]}>
      <div className={classes["title-date"]}>
        <span className={classes["online-statistics-title"]}>ONLINE USERS</span>
        <Space>
          <Select value={dateType} onChange={setDateType}>
            <Option value="week">Week</Option>
            <Option value="month">Month</Option>
            <Option value="year">Year</Option>
          </Select>
          <PickerWithType
            type={dateType}
            defaultValue={selectedDate}
            onChange={(value) => {
              const year=(new Date(value._d)).getFullYear();
              const month=(new Date(value._d)).getMonth();
              const day=(new Date(value._d)).getDate()

              if (dateType === 'year') {
                setSelectedDate(`${year}-01-01`)
              } else if (dateType === 'month') {
                setSelectedDate(`${year}-${month < 9 ? '0' + (month+1) : (month + 1)}-01`)
              } else {
                setSelectedDate(`${year}-${month < 9 ? '0' + (month+1) : (month + 1)}-${day>9?day:'0'+day}`)
              }
            
            }}
          />
          <Select
            style={{
              width: 120,
            }}
            onChange={setPlatformType}
            options={[
              {
                value: 'platform',
                label: 'Platform',
              },
              {
                value: 'all',
                label: 'All',
              },
            ]}
            defaultValue={platformType}
          />
        </Space>
      </div>
      <Line {...config} style={{height:350}}/>
    </div>
  );
}

