const CONSTANTS = {
    JWT:"JWT",
    REMEMBER_ME: "REMEMBER_ME",
    APP_NAME: process.env.REACT_APP_APPLICATION_NAME || "xxx please assign APP NAME in admin/.env file xxxx",
    API_HOST: process.env.REACT_APP_API_URL || "xxx please assign api url in admin/.env file xxxx",
    APP_COLOR: "black",
    LINK_COLOR:"white",
};

export default CONSTANTS;
